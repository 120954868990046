import { Box } from '@mui/system';
import TaskBoard from './TaskBoard';
import "../../styles/style.css"
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
    AppBar,
    Breadcrumbs,
    Link,
    Button
} from '@mui/material'

const BoardTasks = () => {

    const location = useLocation();
    const { board } = location.state || {};

    const navigate = useNavigate();
    
    useEffect(() => {
        console.log("Board", board)
    }, [])
    
  return (
    <Box sx={{ mt: '60px'}}>
        <AppBar sx={{
            backgroundColor: '#1A3353', height: '50px', justifyContent: 'center', marginTop: '-3px', boxShadow: 'none',
            top: '64px', 
            left:'200px',
            width: '-webkit-fill-available'
        }}
            position='fixed'
        >
            <div className='breadcomContainer'>
                <div role='presentation' style={{ marginLeft: '10px' }}>
                    <Breadcrumbs aria-label='breadcrumb'
                        sx={{ '.MuiBreadcrumbs-separator': { color: 'white' } }}
                    >
                    <Link underline='hover' color='lightgray' fontSize='16px' href='/'>
                        Dashboard
                    </Link>
                    <Link
                        underline='hover'
                        color='lightgray'
                        fontSize='16px'
                        onClick={() => navigate('/app/tasks')}
                        style={{ cursor: 'pointer' }}
                    >
                    Tasks
                    </Link>
                    <Link style={{ color: 'white' }} underline='none' fontSize='16px'>{board.name}</Link>
                </Breadcrumbs>
                </div>
            </div>
    </AppBar>
      <div className='tasksComponent'><TaskBoard  board={board} /></div>
    </Box>
  )
}

export default BoardTasks