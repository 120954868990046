import React, { useEffect, useRef, useState } from 'react';
import FeedbackModal from './FeedbackModal';
import '../styles/Feedback.css';
import { Feedback, SERVER } from '../services/ApiUrls';

interface FeedbackFormProps {
  onClose: () => void;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ onClose }) => {
    const [category, setCategory] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [image, setImage] = useState<any>(null);

    const [successMsg, setSuccessMsg] = useState<any>(false);
    const [errorMsg, setErrorMsg] = useState<any>(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const categoryErrorRef = useRef<HTMLDivElement>(null);
    const messageErrorRef = useRef<HTMLDivElement>(null);

    const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCategory(event.target.value);
        if (categoryErrorRef.current) {
            categoryErrorRef.current.style.display = 'none';
        }
        setErrorMsg(false);
        setSuccessMsg(false);
    };

    const handleMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
        if (messageErrorRef.current) {
            messageErrorRef.current.style.display = 'none';
        }
        setErrorMsg(false);
        setSuccessMsg(false);
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedImage = event.target.files?.[0];
        setImage(selectedImage || null);
        setErrorMsg(false);
        setSuccessMsg(false);
    };

    useEffect(() => {
        setEmail(localStorage.getItem('Email') || '');
    }, []);


    const headers: any = {
        Accept: 'application/json',
        Authorization: localStorage.getItem('Token'),
    };

    const submitFeedback = async () => {
        setErrorMsg(false);
        setErrorMsg(false);
        
        let feedbackBody = new FormData();
        feedbackBody.append('email', email);
        feedbackBody.append('category', category);
        feedbackBody.append('message', message);

        if (image) {
            feedbackBody.append('screenshot', image);
        }

        try {
            const response = await fetch(`${SERVER}${Feedback}/`, {
                method: 'POST',
                headers: headers,
                body: feedbackBody,
            });
    
            if (response.ok) {
                // console.log('Feedback:', response);
                setCategory('');
                setMessage('');
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; 
                }
                setImage(null);
                setSuccessMsg(true);
                setTimeout(() => {
                    setSuccessMsg(false);
                    onClose();
                }, 2000)
            } 
            else if(response.status === 403) {
                localStorage.clear();
                window.location.pathname = '/login'
            }
            else {
                console.error('Failed to submit feedback');
                setErrorMsg(true);
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setErrorMsg(true);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (category === '') {
            if (categoryErrorRef.current) {
                categoryErrorRef.current.innerText = 'Select an option from the dropdown.';
                categoryErrorRef.current.style.display = 'block';
            }
        }

        if (message === '') {
            if (messageErrorRef.current) {
                messageErrorRef.current.innerText = 'Please write Message.';
                messageErrorRef.current.style.display = 'block';
            }
        } else if (category !== '' && message !== '') {
            submitFeedback();
        }
    };

  return (
    <>
        <FeedbackModal onClose={onClose}>
            <div className="feedback-form">
                {   
                    successMsg &&
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <div className='successfullContainer'>Thanks for Feedback.</div>
                    </div>  
                } 

                {
                    errorMsg && 
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <div className='errorContainer'>Failed to submit feedback.</div>
                    </div>   
                }   
 
                <h4 className="feedback-h4">🙋‍♂️ Feedback</h4>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="category" className="feedback-label">
                            Feedback Category<sup className="asterisk">*</sup>
                        </label>
                        <select id="category" value={category} onChange={handleCategoryChange}>
                            <option value="" className="option">
                                Please Choose
                            </option>
                            <option value="Just Testing" className="option">
                                Just Testing
                            </option>
                            <option value="Question" className="option">
                                Question
                            </option>
                            <option value="Problem" className="option">
                                Problem
                            </option>
                            <option value="Suggestion" className="option">
                                Suggestion
                            </option>
                            <option value="Compliment" className="option">
                                Compliment
                            </option>
                            <option value="Other" className="option">
                                Other
                            </option>
                        </select>
                        <div className="error feedbackError" ref={categoryErrorRef}></div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="message" className="feedback-label">
                            Message<sup className="asterisk">*</sup>
                        </label>
                        <textarea
                            id="feature"
                            value={message}
                            onChange={handleMessage}
                            placeholder="Message"
                            className="feedback-textarea"
                        />
                        <div className="error feedbackError" ref={messageErrorRef}></div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="image" className="feedback-label">
                            Upload Image
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            id="image"
                            onChange={handleImageChange}
                            className="feedback-input"
                            ref={fileInputRef}
                        />
                    </div>

                    <button type="submit" className="feedback-bttn">
                        Submit Feedback
                    </button>
                </form>
            </div>
        </FeedbackModal>
    </>
  );
};

export default FeedbackForm;
