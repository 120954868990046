import React, { ReactNode, MouseEvent } from 'react';
import '../styles/Feedback.css';

interface ModalProps {
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ onClose, children }) => {
  const handleOverlayClick = (event: MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLDivElement).classList.contains('modal-overlay')) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal">
        <button className="close-button" onClick={onClose} style={{ color: '#1a3353' }}>
          x
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
