import { FaPlus } from "react-icons/fa";
import { useEffect, useMemo, useRef, useState } from "react";
import { Column, Id, Task } from "./types";
import ColumnContainer from "./ColumnContainer";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import TaskCard from "./TaskCard";
import { Box, Dialog } from "@mui/material";
import { fetchData } from "../../components/FetchData";
import { Boards, Columns } from "../../services/ApiUrls";

const defaultCols: Column[] = [
  {
    id: "todo",
    title: "To-Do",
  },
  {
    id: "doing",
    title: "In progress",
  },
  {
    id: "done",
    title: "Done",
  },
];

const defaultTasks: Task[] = [
  {
    id: "1",
    columnId: "todo",
    content: {
        name: "Mehul",
        email: "mehul@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "2",
    columnId: "todo",
    content: {
        name: "Sanket",
        email: "sanket@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "3",
    columnId: "doing",
    content: {
        name: "Kunal",
        email: "kunal@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "4",
    columnId: "doing",
    content: {
        name: "Amit",
        email: "amit@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "5",
    columnId: "done",
    content: {
        name: "Ankush",
        email: "ankush@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "6",
    columnId: "done",
    content: {
        name: "Mahesh",
        email: "mahesh@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "7",
    columnId: "done",
    content: {
        name: "Kaushal",
        email: "kaushal@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "8",
    columnId: "todo",
    content: {
        name: "Tejas",
        email: "tejas@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "9",
    columnId: "todo",
    content: {
        name: "Ronak",
        email: "ronak@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "10",
    columnId: "todo",
    content: {
        name: "Kumar",
        email: "kumar@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "11",
    columnId: "todo",
    content: {
        name: "Anunay",
        email: "anunay@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "12",
    columnId: "doing",
    content: {
        name: "Jaspreet",
        email: "jaspreet@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
  {
    id: "13",
    columnId: "doing",
    content: {
        name: "Anuj",
        email: "anuj@taliun.com",
        mobileNumber: "+(555) 555-1234",
        date: "23-07-2023"
    },
  },
];

interface ColumnInterface {
    id: string;
    name: string;
    board: string;
    order: number;
}

function TaskBoard(props: any) {

    const { board } = props;

    const [columns, setColumns] = useState<Column[]>([]);
    const [tasks, setTasks] = useState<Task[]>(defaultTasks);
    const [activeColumn, setActiveColumn] = useState<Column | null>(null);
    const [activeTask, setActiveTask] = useState<Task | null>(null);
    const [columnName,  setColumnName] = useState("");

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const [columnsData,  setColumnsData] = useState<ColumnInterface[]>([]);

    const columnsId = useMemo(() => columnsData.map((col) => col.id), [columnsData]);

    
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setColumnName("");
        setModalIsOpen(false);
    };
  
    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 10,
        },
      })
    );

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Token'),
        org: localStorage.getItem('org'),
    };

    
    const getColumns = () => {
        fetchData(`${Boards}/${Columns}?board=${board.id}`, 'GET', null as any, headers)
            .then((res: any) => {
                console.log('Columns', res)
                if (res?.results) {
                setColumnsData(res?.results);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        getColumns();
    }, [])

    const headerss = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Token'),
    };


    const data = {
        name: columnName,
        board: board.id,
    }

    const createNewColumn = () => {
        if (columnName === "") {
          inputRef.current?.focus();
        } else {
          fetchData(`${Boards}/${Columns}/`, 'POST', JSON.stringify(data), headerss)
            .then((res: any) => {
              console.log('New-Column created: ', columnName, res)
              if (res) {
                setModalIsOpen(false);
                setColumnName("");
                getColumns();
              }
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        }
    };
  
    return (
      <div className="task-board">
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
        >
          <div className="board-content">
            <div className="columns">
              <SortableContext items={columnsId}>
                {/* {columns.map((col) => (
                  <ColumnContainer
                    key={col.id}
                    column={col}
                    deleteColumn={deleteColumn}
                    updateColumn={updateColumn}
                    createTask={createTask}
                    deleteTask={deleteTask}
                    updateTask={updateTask}
                    tasks={tasks.filter((task) => task.columnId === col.id)}
                  />
                ))} */}
                {columnsData.map((column) => (
                    <ColumnContainer key={column.id} columnData={column} getColumns={getColumns}/>
                ))}
              </SortableContext>
            </div>

            <div className="add-column-button-container"> 
                <button
                    onClick={openModal}
                    className="add-column-button"
                    title="Create-Column"
                >
                    <FaPlus />
                </button>

                {/* Modal for board name */}
                <Dialog
                    open={modalIsOpen}
                    onClose={closeModal}
                >
                    <Box sx={{ width: '400px', display: "flex", flexDirection: "column", padding: "10px 30px 20px 30px", gap: "20px", borderRadius: "4px" }}>
                    <h2 style={{textAlign: "center", color: "#1a3353", fontSize: "20px"}}>Create Column</h2>
                
                    <label className="boardNameModalInput">
                        Column Name:
                        <input
                        type="text"
                        value={columnName}
                        onChange={(e) => setColumnName(e.target.value)}
                        placeholder="Type here ..."
                        ref={inputRef}
                        />
                    </label>

                    {/* Create and Cancel buttons */}
                    <div  className="editTaskCardBttn">
                        <button onClick={createNewColumn}>Create</button>
                        <button onClick={closeModal}>Cancel</button>
                    </div>
                    </Box>
                </Dialog>
            </div>      
          </div>
  
          {createPortal(
            <DragOverlay>
              {activeColumn && (
                <ColumnContainer
                  column={activeColumn}
                  deleteColumn={deleteColumn}
                  updateColumn={updateColumn}
                  createTask={createTask}
                  deleteTask={deleteTask}
                  updateTask={updateTask}
                  tasks={tasks.filter(
                    (task) => task.columnId === activeColumn.id
                  )}
                />
              )}
              {activeTask && (
                <TaskCard
                  task={activeTask}
                  deleteTask={deleteTask}
                  updateTask={updateTask}
                />
              )}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      </div>
    );

    function createTask(columnId: Id) {
        const newTask: Task = {
          id: generateId(),
          columnId,
          content: {
            name: "New Task",
            email: "newtask@example.com",
            mobileNumber: "+(555) 555-1234",
            date: "23-07-2023",
          },
        };
      
        setTasks((prevTasks) => [...prevTasks, newTask]);
      }

  function deleteTask(id: Id) {
    const newTasks = tasks.filter((task) => task.id !== id);
    setTasks(newTasks);
  }

  function updateTask(id: Id, updatedContent: { name?: string; email?: string; mobileNumber?: string; date?: string }) {
  setTasks((prevTasks) => {
    return prevTasks.map((task) => {
      if (task.id === id) {
        return {
          ...task,
          content: {
            ...task.content,
            ...updatedContent,
          },
        };
      }
      return task;
    });
  });
}

//   function createNewColumn() {
//     const columnToAdd: Column = {
//       id: generateId(),
//       title: `Column ${columns.length + 1}`,
//     };

//     setColumns([...columns, columnToAdd]);
//   }

  function deleteColumn(id: Id) {
    const filteredColumns = columns.filter((col) => col.id !== id);
    setColumns(filteredColumns);

    const newTasks = tasks.filter((t) => t.columnId !== id);
    setTasks(newTasks);
  }

  function updateColumn(id: Id, title: string) {
    const newColumns = columns.map((col) => {
      if (col.id !== id) return col;
      return { ...col, title };
    });

    setColumns(newColumns);
  }

  function onDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === "Column") {
      setActiveColumn(event.active.data.current.column);
      return;
    }

    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
      return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveColumn(null);
    setActiveTask(null);

    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveAColumn = active.data.current?.type === "Column";
    if (!isActiveAColumn) return;

    console.log("DRAG END");

    setColumns((columns) => {
      const activeColumnIndex = columnsData.findIndex((col) => col.id === activeId);

      const overColumnIndex = columnsData.findIndex((col) => col.id === overId);

      return arrayMove(columns, activeColumnIndex, overColumnIndex);
    });
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";

    if (!isActiveATask) return;

    // Im dropping a Task over another Task
    if (isActiveATask && isOverATask) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const overIndex = tasks.findIndex((t) => t.id === overId);

        if (tasks[activeIndex].columnId != tasks[overIndex].columnId) {
          // Fix introduced after video recording
          tasks[activeIndex].columnId = tasks[overIndex].columnId;
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }

        return arrayMove(tasks, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "Column";

    // Im dropping a Task over a column
    if (isActiveATask && isOverAColumn) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);

        tasks[activeIndex].columnId = overId;
        console.log("DROPPING TASK OVER COLUMN", { activeIndex });
        return arrayMove(tasks, activeIndex, activeIndex);
      });
    }
  }
}

function generateId() {
  /* Generate a random number between 0 and 10000 */
  return Math.floor(Math.random() * 10001);
}

export default TaskBoard;