// import {
//   Box,
//   Button,
//   CssBaseline,
//   Grid,
//   Link,
//   Stack,
//   Typography,
// } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
// import { AuthUrl, OrgUrl, SERVER } from '../../components/ApiUrls'
// import { GoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
// import AuthComponent from '../../components/AuthSignIn'
// import GoogleLogin from '../../components/GoogleLogin'
// import GoogleLoginButto from '../../components/GoogleLogin2'
//  import Google from '../../components/GoogleLogin1'
// import imgLogo from '../../assets/images/auth/img_logo.png';
// import imgBG from '../../assets/images/auth/img_BG.jpg';
// import imgLogin from '../../assets/images/auth/img_login.png';
import '../../styles/auth.css';
import { googleLogout } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
// import { fetchData } from '../../components/FetchData';
import { useNavigate } from 'react-router-dom';
import imgGoogle from '../../assets/images/auth/google.svg';
import { GoogleButton } from '../../styles/CssStyled';
import { fetchData } from '../../components/FetchData';
import { AuthUrl, OrgUrl } from '../../services/ApiUrls';
import TextField from '@mui/material/TextField';
// import { GoogleButton } from '../../../../react-crm-2.0/src/styles/CssStyled';
import '../../styles/signin.css';
import logo from '../../assets/images/auth/img_logo.png';
import UserDetails from '../users/UserDetails';
import illustration from '../../assets/images/auth/Illustration.png';
import { LoginUrl } from '../../services/ApiUrls';
import { LoginUser } from '../../services/ApiUrls';
import { ForgotPassword } from '../passsword/ForgotPassword';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ActionSettingsBrightness from 'material-ui/svg-icons/action/settings-brightness';

declare global {
  interface Window {
    google: any;
    gapi: any;
  }
}

export default function Login() {

  const navigate = useNavigate();
  const [token, setToken] = useState(false);

  const [hasOrg, setHasOrg] = useState(false);

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'),
  };

  const getOrganization = () => {
    fetchData(`${OrgUrl}/`, 'GET', null as any, headers)
      .then((res: any) => {
        // console.log(res, 'org')
        if (res?.profile_org_list[0]?.org?.id && localStorage.getItem('Token')) {         
          localStorage.setItem('org', res?.profile_org_list[0]?.org?.id); 
          setHasOrg(true);
          localStorage.setItem('orgName', res?.profile_org_list[0]?.org?.name); 
          // localStorage.setItem('orgName', res?.profile_org_list[0]?.org?.name); 
          // console.log("org",res?.profile_org_list);   
          navigate('/app/leads',  {
            state: { orgName: res?.profile_org_list[0]?.org?.name },
          });     
        }else if(localStorage.getItem('Token') && !hasOrg) {
          navigate('/app');
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        setHasOrg(false);
      });
  };
  useEffect(() => {
    if(localStorage.getItem('Token')) {
      getOrganization();
    }
  }, [token]);

  // useEffect(() => {
  //   if (localStorage.getItem('Token')) {
  //     // navigate('/organization')
  //     navigate('/app');
  //   }
  // }, [token]);
  // useEffect(() => {
  //     const token = localStorage.getItem('Token');
  //     if (token) {
  //       navigate('/organization');
  //     }
  //   }, [navigate]);
  // const headers = {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //     //   Authorization: `jwt ${localStorage.getItem('Token')}`,
  //     //   org: localStorage.getItem('org')
  // }

  const emailErrorRef = useRef<HTMLDivElement>(null);
  const passwordErrorRef = useRef<HTMLDivElement>(null);
  const [signinDetails, setSigninDetails] = useState({
    email: '',
    password: '',
  });

  const { email, password } = signinDetails;
  const [showPassword, setShowPassword] = useState(false);


  const handleSigninInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    setSigninDetails((prevDetails) => ({
      ...prevDetails,
      [id]: value,
    }));

    if (id === 'email' && emailErrorRef.current) {
      emailErrorRef.current.style.display = 'none';
    } else if (id === 'password' && passwordErrorRef.current) {
      passwordErrorRef.current.style.display = 'none';
    }
  };

  const handleSignin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
 
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3}$/;

    if (!email.match(emailPattern) && emailErrorRef.current) {
      emailErrorRef.current.style.display = 'block';
    }
    if (
      password === "" &&
      passwordErrorRef.current
    ) {
      passwordErrorRef.current.style.display = 'block';
      passwordErrorRef.current.innerText = 'Please enter password';
    }else {
      // console.log('login');
      if(passwordErrorRef.current) {
        passwordErrorRef.current.innerText = 'Invalid Credentials';
      }
      signin();
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const apiToken = { token: tokenResponse.access_token };
      // const formData = new FormData()
      // formData.append('token', tokenResponse.access_token)
      const head = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      fetchData(`${AuthUrl}/`, 'POST', JSON.stringify(apiToken), head)
        .then((res: any) => {
          // console.log(res.access_token, 'access_token')
          localStorage.setItem('Token', `Bearer ${res.access_token}`);
          setToken(true);
        })
        .catch((error: any) => {
          console.error('Error:', error);
        });
    },
  });

  const loginHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const signin = () => {
    fetchData(`${LoginUser}/`, 'POST', JSON.stringify(signinDetails), loginHeader)
      .then((res: any) => {
        if (res.access) {
          console.log('User Login Details', res);
          localStorage.setItem('Email', email);
          setSigninDetails({
            email: '',
            password: '',      
          })
          localStorage.setItem('Token', `Bearer ${res.access}`);
          localStorage.setItem('refresh', `${res.refresh}`);
          localStorage.setItem('access_token_expiration_time', res.access_token_expiration_time);
          setToken(true);
        }
        else if(res.error && passwordErrorRef.current) {
          passwordErrorRef.current.style.display = 'block';
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // const userProfile = () => {
  //   fetchData(`${signupUrl}/`, 'GET', null as any, headers)
  //     .then((res: any) => {
  //       // console.log(res, 'user')
  //       if (res?.user_obj) {
  //         setUserDetail(res?.user_obj);
  //         console.log('userDetails', res?.user_obj);
  //         //   debugger;
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };

  // useEffect(() => {
  //   if() {

  //   }
  // }, [singupSuccess])

  const singupShowHandler = () => {
    navigate('/signup');
  };

  const forgotPasswordHandler = () => {
    navigate('/forgot-password');
  }
  
  return (
    // <div>
    //   <Stack
    //     direction={{ xs: 'column', sm: 'row' }}
    //     // spacing={{ xs: 2, sm: 2, md: 4 }}
    //     // container xs={12}
    //     //  direction='row'
    //     justifyContent="center"
    //     alignItems="center"
    //     sx={{
    //       height: '100%',
    //       width: '100%',
    //       // overflow: 'hidden',
    //       position: 'fixed',
    //     }}
    //   >
    //     <Grid
    //       container
    //       item
    //       xs={8}
    //       direction="column"
    //       justifyContent="space-evenly"
    //       alignItems="center"
    //       sx={{ height: '100%', overflow: 'hidden' }}
    //     >
    //       <Grid item>
    //         <Grid sx={{ mt: 2 }}>
    //           <img
    //             src={imgLogo}
    //             alt="register_logo"
    //             className="register-logo"
    //           />
    //         </Grid>
    //         {/* <Typography variant='h5' style={{ fontWeight: 'bolder' }}>Sign In</Typography> */}
    //         <Grid item sx={{ mt: 4 }}>
    //           {/* <GoogleLogin
    //                             onSuccess={credentialResponse => {
    //                                 console.log(credentialResponse);
    //                             }}

    //                             onError={() => {
    //                                 console.log('Login Failed');
    //                             }}
    //                         />
    //                         <Button onClick={signout}>logout</Button> */}

    //           <GoogleButton
    //             variant="outlined"
    //             onClick={() => login()}
    //             sx={{ fontSize: '12px', fontWeight: 500 }}
    //           >
    //             Sign in with Google
    //             <img
    //               src={imgGoogle}
    //               alt="google"
    //               style={{ width: '17px', marginLeft: '5px' }}
    //             />
    //           </GoogleButton>
    //           {/* <Grid item sx={{ mt: 2, alignItems: 'center', alignContent: 'center' }}>
    //                             <Grid item sx={{ mt: 1, ml: 6 }}>
    //                                 <div className='authentication_wrapper'>
    //                                     <div className='authentication_block'>
    //                                         <div className='buttons'>
    //                                             <GoogleLogin
    //                                                 onSuccess={credentialResponse => {
    //                                                     console.log(credentialResponse);
    //                                                 }}

    //                                                 onError={() => {
    //                                                     console.log('Login Failed');
    //                                                 }}

    //                                             />
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </Grid>
    //                         </Grid> */}
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //     <Grid
    //       container
    //       item
    //       xs={8}
    //       direction="column"
    //       justifyContent="center"
    //       alignItems="center"
    //       className="rightBg"
    //       sx={{ height: '100%', overflow: 'hidden', justifyItems: 'center' }}
    //     >
    //       <Grid item>
    //         <Stack
    //           sx={{
    //             alignItems: 'center',
    //             //  mt: '-600px'
    //           }}
    //         >
    //           <img
    //             src={imgLogin}
    //             alt="register_ad_image"
    //             className="register-ad-image"
    //           />
    //           <footer className="register-footer">bottlecrm.com</footer>
    //         </Stack>
    //       </Grid>
    //     </Grid>
    //   </Stack>
    // </div>
    <div className="loginContainer">
      <nav className="loginNavbar">
        <img
          src={logo}
          alt="Patient Match"
          onClick={() => navigate('/login')}
        />
      </nav>
      <div className="loginSubContainer">
        <div className="loginLogo">
          <img src={illustration} alt="illustration" />
        </div>
        <div className="loginForm">
          <div className="signIn">
            <div className="signin">
              <h3>Sign In</h3>
              <form onSubmit={handleSignin}>
                <div>
                  <TextField
                  type="email"
                  id="email"
                  label={email ? "Email" : ""}
                  variant="outlined"
                  className="input"
                  value={email}
                  onChange={handleSigninInput}
                />
                  <div className="error" ref={emailErrorRef}>
                    Please enter valid Email.
                  </div>
                </div>

                <div>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  label={password ? "Password" : ""}
                  variant="outlined"
                  className="input"
                  value={password}
                  onChange={handleSigninInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="error" ref={passwordErrorRef}>
                  Invalid credentials
                </div>
              </div>


                <span className="forgotPassword" onClick={forgotPasswordHandler}>Forgot password?</span>

                <div className="bttnContainer bttnSigninGoogle">
                  <button type="submit" className="signinBtn">
                    Sign In
                  </button>
                  {/* <div className="orLine">
                    <div></div>
                    <p>OR</p>
                    <div></div>
                  </div>
                  <GoogleButton
                    variant="outlined"
                    onClick={() => login()}
                    className="googleBttn"
                  >
                    <img
                      src={imgGoogle}
                      alt="google"
                      style={{ width: '20px', marginRight: '8px' }}
                    />
                    Sign in with Google
                  </GoogleButton> */}
                </div>
                <div className="noAccount">
                  No account? <span onClick={singupShowHandler}>Sign up</span>
                </div>
              </form>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
}
