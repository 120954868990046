import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Typography,
  Box,

} from '@mui/material';
import '../../styles/style.css';
import { StatusUrl } from '../../services/ApiUrls';
import { fetchData } from '../../components/FetchData';
import { CustomAppBar } from '../../components/CustomAppBar';
import { FaArrowDown, FaTimes, FaUpload } from 'react-icons/fa';
import { RequiredTextField } from '../../styles/CssStyled';


type FormErrors = {
  name?: string[];
  order?: string[];
  org?: string[];

};
interface FormData {
  name: string;
  order: string;
  org: string;
}
export function EditStatus() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [reset, setReset] = useState(false);
  const [error, setError] = useState(false);
  const [profileErrors, setProfileErrors] = useState<FormErrors>({});
  const [userErrors, setUserErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState<FormData>({
    name: '',
    order: '',
    org: '',
  });

  const setPasswordHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'),
  };

  useEffect(() => {
    setFormData(state?.value);
  }, [state?.id]);

  useEffect(() => {
    if (reset) {
      setFormData(state?.value);
    }
    return () => {
      setReset(false);
    };
  }, [reset]);

  const handleChange = (e: any) => {
    const { name, value, files, type, checked } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: e.target.files?.[0] || null });
    }
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const backbtnHandle = () => {
    navigate('/app/status');

  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    submitForm();
  };

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'),
    org: localStorage.getItem('org'),
  };


  const submitForm = () => {
    const data = {
      name: formData.name,
      order: formData.order,
      org: formData.org,

    };

    fetchData(`${StatusUrl}/${state?.id}/`, 'PUT', JSON.stringify(data), headers)
      .then((res: any) => {
        if (!res.error) {
            resetForm()
            navigate('/app/status')
        }
        if (res.error) {
            setUserErrors(res?.errors)
        }
      })
      .catch(() => { });
  };
  const resetForm = () => {
    setFormData({
      name: '',
      order: '',
      org: '',

    });
    setProfileErrors({});
    setUserErrors({});
  };
  const onCancel = () => {
    setReset(true);
    navigate('/app/status');
  };
  const module = 'Status';
  const crntPage = 'Edit Status';
  const backBtn = state?.edit ? 'Back To Status' : 'Back To Status';

  return (
    <Box sx={{ mt: '60px' }}>
      <CustomAppBar
        backbtnHandle={backbtnHandle}
        module={module}
        backBtn={backBtn}
        crntPage={crntPage}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
      <Box sx={{ mt: '100px' }}>
        <form onSubmit={handleSubmit}>
          <div style={{ padding: '10px' }}>
            <div className="leadContainer">
              <Accordion defaultExpanded style={{ width: '98%' }}>
                <AccordionSummary expandIcon={<FaArrowDown />}>
                  <div className="typography">
                    <Typography
                      style={{ marginBottom: '15px', fontWeight: 'bold' }}
                    >
                      Status Information
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{ width: '98%', color: '#1A3353', mb: 1 }}
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="fieldContainer">
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">Status Name</div>
                        <RequiredTextField
                          required
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          error={
                            !!profileErrors?.name?.[0] ||
                            !!userErrors?.name?.[0]
                          }
                          helperText={
                            profileErrors?.name?.[0] ||
                            userErrors?.name?.[0] ||
                            ''
                          }
                        />
                      </div>
                      <div className="fieldSubContainer">
                        <div className="fieldTitle">Order</div>
                        <RequiredTextField
                          required
                          name="order"
                          value={formData.order}
                          onChange={handleChange}
                          style={{ width: '70%' }}
                          size="small"
                          error={
                            !!profileErrors?.order?.[0] ||
                            !!userErrors?.order?.[0]
                          }
                          helperText={
                            profileErrors?.order?.[0] ||
                            userErrors?.order?.[0] ||
                            ''
                          }
                        />
                      </div>
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </form>
      </Box>
    </Box>
  );
}
