import { OrgUrl, SERVER } from '../services/ApiUrls'

export function fetchData (url:any, method:any, data = '', header:any) {
  return fetch(`${SERVER}${url}`, {
    method,
    headers: header,
    body: data
  }).then((response) => {
    // if (response.status === 403) {
    //   localStorage.clear();
    //   window.location.pathname = '/login';
    // }
    //Return the JSON response
    return response.json();
  });
}
