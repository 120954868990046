import { useRef, useState } from "react";
import { FaTrash, FaCalendarAlt, FaEllipsisV, FaArrowRight } from "react-icons/fa";
import { Id, Task } from "./types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "../../styles/style.css";
import "../../styles/company.css";
import { Box, Dialog } from "@mui/material";
import { Avatar } from '@mui/material';
import { IconButton, List, ListItem, Popover } from '@mui/material';


interface Props {
  task: Task;
  deleteTask: (id: Id) => void;
  updateTask: (id: Id, updatedContent: { name?: string; email?: string; mobileNumber?: string; date?: string }) => void;
}

function TaskCard(props: any) {

  const {cardData, getCards } = props;
  
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const [editMode, setEditMode] = useState(false);

  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [editedContent, setEditedContent] = useState(task.content);

  
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // const dropdownRef = useRef<HTMLDivElement>(null);

  
  // const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // const toggleDropdown = () => {
  //   setIsDropdownVisible(!isDropdownVisible);
  // };

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: cardData.id,
    data: {
      type: "Task",
      cardData,
    },
    disabled: editMode,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const saveChanges = () => {
    // updateTask(task.id, editedContent);
    closeModal();
  };

  if (isDragging) {
    return <div ref={setNodeRef} style={style} className="dragged-task" />;
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="task"
      onMouseEnter={() => {
        setMouseIsOver(true);
      }}
      onMouseLeave={() => {
        setMouseIsOver(false);
      }}
    >
      <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
        <div className="task-content">
          {/* <div style={{fontFamily: "Inter", fontSize: "16px", fontWeight: "700", textAlign: "left", color: "#203251"}}>{task.content.name}</div> */}
          {/* <div>{task.content.email}</div>
          <div>{task.content.mobileNumber}</div> */}
        </div>

        <FaEllipsisV style={{cursor: "pointer", color: "#203251", paddingTop: "5px"}} onClick={handleClick}  />
        
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <List disablePadding className="taskCardList"> 
          <ListItem disablePadding className="taskCardListItem" style={{display: "flex", justifyContent: "space-between"}}>
            Move To   
            <FaArrowRight style={{ width: "12px"}}/>
          </ListItem >
          <ListItem disablePadding className="taskCardListItem">
            <button onClick={openModal} className="edit-button" style={{all: "unset", cursor: "pointer"}}>
              Edit Task
            </button>         
          </ListItem >

          <ListItem disablePadding style={{height: "2px", width: "100%", backgroundColor: "grey", padding: "0"}}></ListItem>

          <ListItem disablePadding className="taskCardListItem">
            Choose Templates        
          </ListItem >
          <ListItem disablePadding className="taskCardListItem">
            Add Notes         
          </ListItem >

          <ListItem disablePadding style={{height: "2px", width: "100%", backgroundColor: "grey", padding: "0"}}></ListItem>

          <ListItem disablePadding className="taskCardListItem">
            <button
              // onClick={() => {
              //   deleteTask(task.id);
              // }}
              className="delete-button"
              style={{ cursor: "pointer"}}
            >
              Delete
            </button>    
              </ListItem>
          </List>
        </Popover>
        
        {/* <div className="dropdownEllipsis">
          <FaEllipsisV style={{cursor: "pointer", color: "#203251", paddingTop: "5px"}} onClick={toggleDropdown} />
                           
              {isDropdownVisible &&
                <div className="dropdownEllipsis-content" ref={dropdownRef}>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <button onClick={openModal} className="edit-button">
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        deleteTask(task.id);
                      }}
                      className="delete-button"
                    >
                      <FaTrash />
                    </button>
                  </div>
            </div>}
        </div>       */}
      </div>

      <div style={{width: "100%", display: "flex", alignItems: "center", gap: "10px"}}><FaCalendarAlt  fill="#1a3353"/>
       {/* {task.content.date} */}
       </div> 

      <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center"}}>
        <div style={{background: "#EFF4FB", borderRadius: "6px", padding: "8px 16px", color: "#4D78EF", fontSize: "12px", fontWeight: "500"}}>Status</div>
        <Avatar style={{width: "28px", height: "28px"}}/>
      </div>    

      {/* {mouseIsOver && (
        <>
          <button onClick={openModal} className="edit-button">
            Edit
          </button>
          <button
            onClick={() => {
              deleteTask(task.id);
            }}
            className="delete-button"
          >
            <FaTrash />
          </button>
        </>
      )} */}

      {/* Modal for editing */}
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
      >
         <Box sx={{ width: '400px', display: "flex", flexDirection: "column", padding: "20px 30px", gap: "20px", borderRadius: "4px" }}>
          <h2 style={{textAlign: "center", color: "#1a3353"}}>Edit Task</h2>
          {/* Input fields for each property */}
          <label className="editTaskCard">
            Name:
            <input
              type="text"
              // value={editedContent.name}
              // onChange={(e) =>
              //   setEditedContent((prev) => ({ ...prev, name: e.target.value }))
              // }
            />
          </label>
          <label className="editTaskCard">
            Email:
            <input
              type="text"
              // value={editedContent.email}
              // onChange={(e) =>
              //   setEditedContent((prev) => ({ ...prev, email: e.target.value }))
              // }
            />
          </label>
          <label className="editTaskCard">
            Mobile Number:
            <input
              type="text"
              // value={editedContent.mobileNumber}
              // onChange={(e) =>
              //   setEditedContent((prev) => ({
              //     ...prev,
              //     mobileNumber: e.target.value,
              //   }))
              // }
            />
          </label>
          <label className="editTaskCard">
            Date:
            <input
              type="date"
              // value={editedContent.date}
              // onChange={(e) =>
              //   setEditedContent((prev) => ({ ...prev, date: e.target.value }))
              // }
            />
          </label>
          {/* Save and Cancel buttons */}
          <div  className="editTaskCardBttn">
              <button onClick={saveChanges}>Save</button>
              <button onClick={closeModal}>Cancel</button>
          </div>
        </Box>
      </Dialog>
    </div>
  );
}

export default TaskCard;
