
  import { useRef, useEffect, useState } from 'react';
  import { useGoogleLogin } from '@react-oauth/google';
  // import { fetchData } from '../../components/FetchData';
  import { useNavigate } from 'react-router-dom';
  import TextField from '@mui/material/TextField';
  import '../../styles/signin.css';
  import '../../styles/Password.css';
  import logo from '../../assets/images/auth/img_logo.png';
  import illustration from '../../assets/images/auth/Illustration.png';
  
  declare global {
    interface Window {
      google: any;
      gapi: any;
    }
  }
  
  export function ForgotPassword() {
    const navigate = useNavigate();
  
    const [inputDetail, setInputDetail] = useState({
     email: ''
    });
  
    const {email} = inputDetail;
    const emailErrorRef = useRef<HTMLDivElement>(null);
    
  
    const handleInput = (event: any) => {
      const { id, value } = event.target;
  
      setInputDetail((prevDetails) => ({
        ...prevDetails,
        [id]: value,
      }));
  
      if (id === 'email' && emailErrorRef.current) {
        emailErrorRef.current.style.display = 'none';
      }
      
    };
  
    const handleForgotPassword = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
  
      const emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  
      if (
        !email.match(emailPattern) &&
        emailErrorRef.current
      ) {
        emailErrorRef.current.style.display = 'block';
      }
  
      else {
        
      }
    };
  
    const singinShowHandler = () => {
      navigate('/login');
    };
  
    return (
      <div className="loginContainer signupContainer">
        <nav className="loginNavbar">
          <img
            src={logo}
            alt="Patient Match"
            onClick={() => navigate('/login')}
          />
        </nav>
        <div className="loginSubSignupContainer">
        <div className="signupLogo">
            <img src={illustration} alt="illustration" />
          </div>
          <div className="signupForm forgotContainer">    
              <div className="signup forgotResetContainer">
                <div>
                <h3>Forgot Password</h3>
                <p className='forgotText'>Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.</p>
                </div>
                <form onChange={handleInput} onSubmit={handleForgotPassword}>                 
                    <div>
                        <TextField
                            type="text"
                            id="email"
                            label="Email"
                            variant="outlined"
                            className="input "
                            value={email}
                        />
                        <div className="error" ref={emailErrorRef}>
                            Email is not valid.
                        </div>
                    </div>
  
                    <div className="bttnContainer bttnSigninGoogle">
                        <button type="submit" className="signinBtn">
                        Send
                        </button>
                        <div className="orLine">
                        <div></div>
                        <p>OR</p>
                        <div></div>
                        </div>
                        <div className="noAccount rememberPassword">
                        Remember Password?{' '}
                        <span onClick={singinShowHandler}>Sign in</span>
                    </div>
                  </div>          
                </form>
              </div>     
          </div>
        </div>
      </div>
    );
  }
  