import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Box, Button, Card, Stack, Tab, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tabs, Toolbar, Typography, Paper, TableCell, IconButton, Checkbox, Tooltip, TableSortLabel, alpha, Select, MenuItem, Container } from '@mui/material'
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { CustomTab, CustomToolbar, FabLeft, FabRight } from '../../styles/CssStyled';
import { getComparator, stableSort } from '../../components/Sorting';
import { FaAd, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { fetchData } from '../../components/FetchData';
import { AccountsUrl, SourceUrl, UsersUrl, UserUrl } from '../../services/ApiUrls';
import { DialogModal } from './DeleteModal'
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from '../../components/DeleteModal';




interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'order',
        numeric: true,
        disablePadding: false,
        label: 'Order'
    },
    {
        id: 'name',
        numeric: true,
        disablePadding: false,
        label: 'Source Name'
    },
    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions'
    }
]

function EnhancedTableHead(props: any) {
    const {
        onSelectAllClick, order, orderBy,
        numSelected, rowCount,
        numSelectedId, isSelectedId,
        onRequestSort
    } = props

    const createSortHandler =
        (property: any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {
                    headCells.map((headCell) => (
                        headCell.label === 'Actions' ?
                            <TableCell
                                sx={{ fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}
                                key={headCell.id}
                                align={headCell.numeric ? 'left' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}>{headCell.label}</TableCell>
                            : <TableCell
                                sx={{ fontWeight: 'bold', color: 'rgb(26, 51, 83)' }}
                                key={headCell.id}
                                align={headCell.numeric ? 'left' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {
                                        orderBy === headCell.id
                                            ? (
                                                <Box component='span' sx={{ display: 'none' }}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            )
                                            : null
                                    }
                                </TableSortLabel>
                            </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    )
}

const EnhancedTableToolbar = (props: any) => {
    const [isDelete, setIsDelete] = useState(false)
    const { numSelected } = props


    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('Token')}`,
        org: localStorage.getItem('org')
    }

    const onDelete = (id: any) => {
        fetchData(`${SourceUrl}/${id}/`, 'delete', null as any, headers)
            .then((data: any) => {
                if (!data.error) {
                    props.getAccount()
                    setIsDelete(false)
                }
            })
            .catch(() => {
            })
    }
    function deleteHandle() {
        setIsDelete(!isDelete)
    }

    const onclose = () => {
        setIsDelete(!isDelete)
    }

    return (
        <div>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                <Tooltip title='Delete'>
                    <Button
                        variant='outlined'
                        onClick={numSelected > 0 ? deleteHandle : undefined}
                        startIcon={<FaTrashAlt color='red' style={{ width: '12px' }} />}
                        size='small'
                        color='error'
                        sx={{
                            opacity: 0.7, fontWeight: 'bold',
                            textTransform: 'capitalize',
                            color: 'red',
                            borderColor: 'darkgrey'
                        }}
                    >
                        Delete
                    </Button>
                </Tooltip>
                {numSelected > 0
                    ? (
                        <Typography
                            sx={{ flex: '1 1 100%', margin: '5px' }}
                            color='inherit'
                            variant='subtitle1'
                            component='div'
                        >
                            {numSelected} selected
                        </Typography>
                    )
                    : ''}
            </Toolbar>
            {
                isDelete
                    ? <DialogModal
                        accountData={props.numSelectedId}
                        numSelected={numSelected} isDelete={isDelete}
                        onClose={onclose}
                        onDelete={onDelete}
                        selectedCheckBx={props.isSelectedId}
                    />
                    : ''
            }
        </div>
    )
}

type Item = {
    id: string;
};
export default function Status() {
    const navigate = useNavigate()
    const [tab, setTab] = useState('active');
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('Website')
    const [initial, setInitial] = useState(true)
    const [openOffset, setOpenOffset] = useState(0)
    const [openValue] = useState(1)
    const [closeOffset, setCloseOffset] = useState(0)
    const [setCloseValue] = useState(1)
    const [deleteItems, setDeleteItems] = useState([])
    const [page, setPage] = useState(0)
    const [values, setValues] = useState(10)
    const [dense] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [usersData, setUsersData] = useState([])
    const [deleteItemId, setDeleteItemId] = useState('')
    const [loader, setLoader] = useState(true)
    const [isDelete, setIsDelete] = useState(false)
    const [activeSource, setActiveSource] = useState<Item[]>([])
    const [activeUsersCount, setActiveUsersCount] = useState(0)
    const [activeUsersOffset, setActiveUsersOffset] = useState(0)
    const [inactiveUsers, setInactiveUsers] = useState([])
    const [InactiveUsersCount, setInactiveUsersCount] = useState(0)
    const [inactiveUsersOffset, setInactiveUsersOffset] = useState(0)
    const [deleteRowModal, setDeleteRowModal] = useState(false)

    const [selectOpen, setSelectOpen] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);
    const [selectedId, setSelectedId] = useState<string[]>([]);
    const [isSelectedId, setIsSelectedId] = useState<boolean[]>([]);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);

    useEffect(() => {
        getStatus()
    }, [])

    const handleChangeTab = (e: SyntheticEvent, val: any) => {
        setTab(val)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
        setValues(parseInt(event.target.value, 10))
    }
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Token'),
        org: localStorage.getItem('org')
    }
    const getStatus = () => {
        fetchData(`${SourceUrl}/`, 'GET', null as any, headers)
            .then((res: any) => {
                if (!res.error) {
                    console.log(res, 'users')
                    if (initial) {
                        setActiveSource(res?.active_source?.active_source)
                    }
                }
            })
            .catch((error: any) => console.error('error', error))
    }

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    type SelectedItem = string[];
    const isSelected = (name: string, selected: SelectedItem): boolean => {
        return selected.indexOf(name) !== -1;
    };

    const deleteItemBox = (deleteId: any) => {
        setDeleteItemId(deleteId)
        setIsDelete(!isDelete)
    }

    const onclose = () => {
        setIsDelete(!isDelete)
    }

    const onDelete = (id: any) => {
        fetchData(`${SourceUrl}/${id}/`, 'delete', null as any, headers)
            .then((data) => {
                if (!data.error) {
                    getStatus()
                    setIsDelete(false)
                }
            })
            .catch(() => {
            })
    }



    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 7) : 0

    const onAddUser = () => {
        navigate('/app/source/add-source')
    }
    const deleteRow = (id: any) => {
        setSelectedId(id)
        setDeleteRowModal(!deleteRowModal)
    }

    const getStatusDetail = (id: any) => {
        fetchData(`${SourceUrl}/${id}/`, 'GET', null as any, headers)
            .then((res) => {
                console.log(res, 'res');
                    navigate('/app/source/edit-source', {
                        state: {
                            value: {
                                name: res?.name,
                                order: res?.order,
                                org: res?.org,
                            }, id: id, edit: true
                        }
                    })
                // }
            })
    }

    const deleteRowModalClose = () => {
        setDeleteRowModal(false)
        setSelectedId([])
    }
    const DeleteItem = () => {
        fetchData(`${SourceUrl}/${selectedId}/`, 'DELETE', null as any, headers)
            .then((res: any) => {
                console.log('delete:', res);
                if (!res.error) {
                    deleteRowModalClose()
                    getStatus()
                }
            })
            .catch(() => {
            })
    }


    const handleSelectAllClick = () => {
        if (selected.length === activeSource.length) {
            setSelected([]);
            setSelectedId([]);
            setIsSelectedId([]);
        } else {
            const newSelectedIds = activeSource.map((user) => user.id);
            setSelected(newSelectedIds);
            setSelectedId(newSelectedIds);
            setIsSelectedId(newSelectedIds.map(() => true));
        }
    };

    const modalDialog = 'Are You Sure You want to delete this Source?'
    const modalTitle = 'Delete Status'


    return (
        <Box sx={{ mt: '60px' }}>
            <CustomToolbar>
                <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button
                        variant='contained'
                        startIcon={<FiPlus className='plus-icon' />}
                        onClick={onAddUser}
                        className={'add-button'}
                    >
                        Add Source
                    </Button>
                </Stack>
            </CustomToolbar>
            <Container sx={{ width: '100%', maxWidth: '100%', minWidth: '100%' }}>
                <Container sx={{ width: '101%', minWidth: '101%', m: '15px 0px 0px -30px' }}>
                    <Paper sx={{ width: '100%', mb: 2, p: '0px 15px 0px 15px' }}>
                        <TableContainer>
                            <Table>
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={activeSource?.length}
                                    numSelectedId={selectedId}
                                    isSelectedId={isSelectedId}
                                />
                                <TableBody>
                                    {
                                        activeSource?.length > 0
                                            ? stableSort(activeSource, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, index: any) => {
                                                    return (
                                                        <TableRow
                                                            tabIndex={-1}
                                                            key={index}
                                                            sx={{
                                                                border: 0,
                                                                '&:nth-of-type(even)': {
                                                                    backgroundColor: 'whitesmoke'
                                                                },
                                                                color: 'rgb(26, 51, 83)',
                                                                textTransform: 'capitalize'
                                                            }}
                                                        >
                                                            <TableCell
                                                                align='left'
                                                                sx={{ border: 0, color: 'rgb(26, 51, 83)' }}
                                                            >
                                                                <div style={{ display: 'flex' }}>
                                                                    {item?.order ? item.order : '---'}
                                                                </div>
                                                            </TableCell>

                                                            <TableCell
                                                                align='left'
                                                                sx={{ cursor: 'pointer', color: '#3E79F7', textTransform: 'none' }}
                                                            >
                                                                {item?.name ? item.name : '---'}
                                                            </TableCell>

                                                            <TableCell align='left' sx={{ border: 0 }}>
                                                                <IconButton>
                                                                    <FaEdit
                                                                        onClick={() => getStatusDetail(item.id)}
                                                                        style={{ fill: '#1A3353', cursor: 'pointer', width: '18px' }} title='Edit Status'
                                                                    />
                                                                </IconButton>
                                                                <IconButton>
                                                                    <FaTrashAlt onClick={() => deleteRow(item?.id)} style={{ fill: '#1A3353', cursor: 'pointer', width: '15px' }} title='Delete Status' />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            : ''
                                    }
                                    {
                                        emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container>
            </Container>
            <DeleteModal
                onClose={deleteRowModalClose}
                open={deleteRowModal}
                id={selectedId}
                modalDialog={modalDialog}
                modalTitle={modalTitle}
                DeleteItem={DeleteItem}
            />
        </Box>
    )
}