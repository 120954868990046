import "../../styles/style.css"
import "../../styles/board.css"
import { fetchData } from '../../components/FetchData';
import { Boards } from '../../services/ApiUrls';
import { useEffect, useRef, useState } from 'react';
import { FaPlus, FaEllipsisV } from "react-icons/fa";
import { Box, Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import { List, ListItem, Popover } from '@mui/material';
import { set } from "date-fns";

interface Board {
  id: string;
  name: string;
  org: string
}

const Tasks = () => {

  const [boards,  setBoards] = useState<Board[]>([]);
  const [boardName,  setBoardName] = useState("");
  const [editBoardName,  setEditBoardName] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [currentBoard, setCurrentBoard] = useState<Board | null>(null);
  const navigate = useNavigate();

  
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: any, board: Board) => {
    setAnchorEl(event.currentTarget);
    setCurrentBoard(board);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'),
    org: localStorage.getItem('org'),
  };

  const getBoards = () => {
    fetchData(`${Boards}/`, 'GET', null as any, headers)
      .then((res: any) => {
        console.log('Board', res)
        if (res?.results) {
          setBoards(res?.results);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    getBoards();
  }, []);


  const headerss = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'), 
  };

  const data = {
    name: boardName,
    org: localStorage.getItem('org'),
  }

  const createBoard = () => {
    if (boardName === "") {
      inputRef.current?.focus();
    } else {
      fetchData(`${Boards}/`, 'POST', JSON.stringify(data), headerss)
        .then((res: any) => {
          console.log('New-Board created: ', boardName, res)
          if (res) {
            setModalIsOpen(false);
            setBoardName("");
            getBoards();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const columnName = (board: any) => {
    navigate('/app/tasks/tasks-board', {
      state: { 
        board: board
      } 
    });
  }

  const deleteBoard = (id: any) => {
    fetchData(`${Boards}/${id}/`, 'DELETE', null as any, headerss)
      .then((res: any) => {
        if (res) {
          console.log('Board Deleted', res);
          getBoards();
          setEditModalIsOpen(false);
          setAnchorEl(null);
        }
      })
      .catch((error) => {
        getBoards();
        setEditModalIsOpen(false);
        setAnchorEl(null);
        console.error('Error:', error);
      });
  };

  const newData = {
    name: editBoardName,
    org: localStorage.getItem('org'),
  }

  const editBoard = (id: any) => {
    if (editBoardName === "") {
      inputRef.current?.focus();
    } else {
      fetchData(`${Boards}/${id}/`, 'PATCH', JSON.stringify(newData), headerss)
        .then((res: any) => {
          console.log('Board-Name updated: ', editBoardName, res)
          if (res) {
            setEditModalIsOpen(false);
            setEditBoardName("");
            setAnchorEl(null);
            getBoards();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setBoardName("");
    setModalIsOpen(false);
  };
  
  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditBoardName("");
    setEditModalIsOpen(false);
  };

  return (
    <Box sx={{ mt: '60px'}}>
      <div style={{ height: "50px", backgroundColor: "#203251"}}></div>
      <div className='boardsComponent'>      
        {boards.map((board) => 
          <div className='boardsNameContainer'>
            <button key={board.id} onClick={() => columnName(board)}>{board.name}</button>
            <FaEllipsisV className="ellipsisButton" fill='#1a3353' onClick={(event) => handleClick(event, board)}  />
              <Popover
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id={open && currentBoard ? `simple-popover-${currentBoard.id}` : undefined}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <List disablePadding className="taskCardList" style={{width: "150px"}}>        
                  <ListItem disablePadding className="taskCardListItem" onClick={openEditModal}  style={{ cursor: "pointer"}}>
                    <button className="edit-button" style={{all: "unset", cursor: "pointer"}}>
                      Edit 
                    </button>         
                  </ListItem >

                  <ListItem disablePadding className="taskCardListItem"  onClick={() => deleteBoard(currentBoard?.id)} style={{ cursor: "pointer"}}>
                    <button
                      className="delete-button"
                    >
                      Delete
                    </button>    
                    </ListItem>
                </List>
              </Popover>

              {/* Modal for edit board name */}
              <Dialog
                open={editModalIsOpen}
                onClose={closeEditModal}
              >
                <Box sx={{ width: '400px', display: "flex", flexDirection: "column", padding: "10px 30px 20px 30px", gap: "20px", borderRadius: "4px" }}>
                  <h2 style={{textAlign: "center", color: "#1a3353", fontSize: "20px"}}>Edit Board</h2>
                  
                  <label className="boardNameModalInput">
                    Board Name:
                    <input
                      type="text"
                      value={editBoardName}
                      onChange={(e) => setEditBoardName(e.target.value)}
                      placeholder="Type here ..."
                      ref={inputRef}
                    />
                  </label>

                  {/* Edit and Cancel buttons */}
                  <div  className="editTaskCardBttn">
                      <button onClick={() => editBoard(currentBoard?.id)}>Edit</button>
                      <button onClick={closeEditModal}>Cancel</button>
                  </div>
                </Box>
              </Dialog>
          </div>

          
        )}

        <div className='addBoardsContainer'>
          <button onClick={openModal} title="Create-Board"><FaPlus fill='#1a3353'/></button>
        </div>
      </div>

      
      {/* Modal for Create Board */}
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
      >
         <Box sx={{ width: '400px', display: "flex", flexDirection: "column", padding: "10px 30px 20px 30px", gap: "20px", borderRadius: "4px" }}>
          <h2 style={{textAlign: "center", color: "#1a3353", fontSize: "20px"}}>Create Board</h2>
          
          <label className="boardNameModalInput">
            Board Name:
            <input
              type="text"
              value={boardName}
              onChange={(e) => setBoardName(e.target.value)}
              placeholder="Type here ..."
              ref={inputRef}
            />
          </label>

          {/* Create and Cancel buttons */}
          <div  className="editTaskCardBttn">
              <button onClick={createBoard}>Create</button>
              <button onClick={closeModal}>Cancel</button>
          </div>
        </Box>
      </Dialog>
    </Box>
  )
}

export default Tasks